<template>
  <div class="skeleton" :style="{ width, height, borderRadius }"></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SkeletonLoader",
  props: {
    width: {
      type: String,
      default: "100%",
    } as const,
    height: {
      type: String,
      default: "100%",
    } as const,
    borderRadius: {
      type: String,
      default: "5px",
    } as const,
  },
});
</script>

<style scoped>
.skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #f8f8f8 50%, #f0f0f0 75%);
  background-size: 400% 100%;
  animation: skeleton-flicker 1.2s ease-in-out infinite;
}

@keyframes skeleton-flicker {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
</style>
