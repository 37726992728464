import { convertToCamelCase } from "../utils/types";
import {
  ApiError,
  Decision,
  DecisionStatusEnum,
  DefaultService,
  DetailedDecision,
} from "../api.generated";
import {
  ProductCodesRequest,
  ProductDetailsForBackOfficeResponse,
  ProductDetailsService,
} from "../api/scion";
import { DecisionType } from "../models";
import { TIER_3, TIER_6, TIER_7 } from "../constants";
import { captureException } from "../tracing/sentry";

export async function getDecisions(
  decisionStatus: DecisionStatusEnum,
  productCodes: Array<string>
): Promise<Array<DecisionType>> {
  let select_tier_3_products_only = true;

  try {
    let response = await DefaultService.getApiV1Decision2(
      decisionStatus,
      productCodes.join(","),
      select_tier_3_products_only
    );
    if (response && response.decisions) {
      return response.decisions.map((d) =>
        convertToCamelCase<DetailedDecision, DecisionType>(
          d as DetailedDecision
        )
      );
    }

    return [];
  } catch (e) {
    let result = <ApiError>e;
    console.error(
      `Error when calling decision api for all decisions: ${result.message}`,
      e
    );
    throw e;
  }
}

export async function getDecisionsForCustomerAndProducts(
  customerId: string,
  productCodes: Array<string>
): Promise<Array<DecisionType>> {
  const productCodesAsString = productCodes.join(",");
  try {
    let response = await DefaultService.getApiV1Decision(
      customerId,
      productCodesAsString,
      [TIER_3, TIER_6, TIER_7].join(",")
    );
    if (response && response.decisions) {
      return response.decisions.map((d) =>
        convertToCamelCase<Decision, DecisionType>(d)
      );
    }

    return [];
  } catch (e) {
    let result = <ApiError>e;
    // If the customer does not exist, it means that the customer has not submitted T3 forms.
    if (result.status === 404) {
      return [];
    }
    console.error(
      `Error when calling decision api for customer and product: ${result.message}`,
      e
    );
    throw e;
  }
}

export async function getProductDetailsForBackOffice(
  productCodes: ProductCodesRequest
): Promise<ProductDetailsForBackOfficeResponse> {
  try {
    const productDetails =
      await ProductDetailsService.getProductDetailsForBackOffice(productCodes);
    return productDetails;
  } catch (e) {
    let result = <ApiError>e;
    console.error(`Error when calling decision api: ${result.message}`, e);
    throw e;
  }
}
