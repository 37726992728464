<script setup lang="ts">
import {
  TwoBox,
  TwoTag,
  TwoText,
  TwoButton,
  TwoDivider,
} from "@wegift/two-components";
import { DecisionType } from "../models";
import { formatDate } from "../utils/datetime";
import { caseReferenceFormatter } from "../utils/caseReferenceFormatter";
import { ProductCodeToNameMap } from "../utils/types";
import { getProductName } from "../stores/product";
import SkeletonLoader from "../components/SkeletonLoader.vue";

const emit = defineEmits(["handleProvideInfo", "handleViewHistory"]);

const { listDecisions } = defineProps<{
  listDecisions: DecisionType[];
  tag: string;
  colorTag: string;
  showDate?: boolean;
  provideInfo?: boolean;
  viewHistory?: boolean;
  productCodesAndNames: ProductCodeToNameMap | null;
}>();

function handleProvideInfo(caseReference: string) {
  emit("handleProvideInfo", caseReference);
}

function handleViewHistory(caseReference: string) {
  emit("handleViewHistory", caseReference);
}
</script>

<template>
  <two-box>
    <div v-for="(decision, idx) in listDecisions">
      <div class="grid grid-cols-5 items-center">
        <SkeletonLoader v-if="!productCodesAndNames" width="80%" />
        <two-text class="font-bold col-span-1" v-if="productCodesAndNames">
          {{ getProductName(productCodesAndNames, decision.productCode) }}
        </two-text>

        <div class="col-span-3">
          <two-tag :bgColor="colorTag" class="mr-9">{{ tag }}</two-tag>
          <two-text v-if="showDate">
            {{ formatDate(decision.decisionTime) }}
          </two-text>
          <two-text v-if="decision.caseReference" class="ml-9">
            Case Ref: {{ caseReferenceFormatter(decision.caseReference) }}
          </two-text>
        </div>
        <div class="col-span-1 justify-self-end">
          <two-button
            v-if="provideInfo && decision.caseReference"
            class="btn-primary"
            @click="handleProvideInfo(decision.caseReference as string)"
          >
            Provide information
          </two-button>
          <two-button
            v-if="viewHistory && decision.caseReference"
            @click="handleViewHistory(decision.caseReference as string)"
            class="btn-secondary"
          >
            View case history
          </two-button>
        </div>
      </div>
      <two-divider v-if="idx !== listDecisions.length - 1" class="my-4" />
    </div>
  </two-box>
</template>
