<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import _ from "lodash";
import { useGlobalStore } from "../stores/global";
import {
  getDecisionsForCustomerAndProducts,
  getProductDetailsForBackOffice,
} from "../services/decisions";
import DecisionItems from "../components/DecisionItems.vue";
import EmptyProductApprovals from "../components/EmptyProductApprovals.vue";
import { TwoText, LoadingSpinner } from "@wegift/two-components";
import { DecisionType } from "../models";
import { BannerType } from "../utils/types";
import { DecisionStatusEnum } from "../api.generated/models/DecisionStatusEnum";
import { CUSTOMER_ACTION_EDIT, CUSTOMER_ACTION_VIEW } from "../constants";
import Banner from "../components/Banner.vue";
import { captureException } from "../tracing/sentry";
import extractProductNames from "../utils/extractProductNames";
import { useProductStore } from "../stores/product";

type DecisionStatus = {
  [key in DecisionStatusEnum]: DecisionType[];
};

const { isConnect } = defineProps<{
  isConnect: boolean;
}>();

const router = window.globalRouter || useRouter();
const globalStore = useGlobalStore();
const productStore = useProductStore();
let groupDecisions = ref<DecisionStatus>();
let errorMessage = ref();
let isLoading = ref();
let isEmptyProductApprovals = ref(false);
let productCodesAndNames = ref(productStore.productCodesAndNames);
let productNames = ref();

onMounted(async () => {
  const { customerId } = globalStore;
  if (!customerId) return;

  // Fetch decisions
  await fetchDecisions();

  // If already productCodesAndNames exists in store, don't make the scion call
  if (!productCodesAndNames.value) {
    const productCodes = extractProductNames(groupDecisions.value);

    // Get product details for fetched decisions
    const { products } = await getProductDetailsForBackOffice({
      product_codes: productCodes,
    });
    productNames.value = products;

    // Use reduce to create codeNameObject
    productCodesAndNames.value = products.reduce((acc, { code, name }) => {
      acc[code] = name;
      return acc;
    }, {} as { [code: string]: string });
  }
});

const fetchDecisions = async () => {
  try {
    const { customerId } = globalStore;
    if (!customerId) return;
    isLoading.value = true;
    const decisions = await getDecisionsForCustomerAndProducts(customerId, []);
    if (decisions.length === 0) {
      isEmptyProductApprovals.value = true;
    }
    groupDecisions.value = _.groupBy(decisions, "decision") as DecisionStatus;

    const areAllDecisionRequiresCustomerAction =
      groupDecisions.value[DecisionStatusEnum.REQUIRES_CUSTOMER_ACTION]
        ?.length == decisions.length;
    if (areAllDecisionRequiresCustomerAction) {
      isEmptyProductApprovals.value = true;
    }
  } catch (e) {
    captureException("An error occurred while fetching the decisions: ", e);
    errorMessage.value = "An error occurred! 😧";
  } finally {
    isLoading.value = false;
  }
};

const handleProvideInfo = (caseReference: string) => {
  router.push({
    name: "provide-information",
    params: {
      action: CUSTOMER_ACTION_EDIT,
      caseReference,
    },
  });
};

const handleViewCase = (caseReference: string) => {
  router.push({
    name: "provide-information",
    params: {
      action: CUSTOMER_ACTION_VIEW,
      caseReference,
    },
  });
};
</script>

<template>
  <div>
    <two-text class="mb-4 font-heading font-semiblack" type="text-3xl" tag="h1">
      Product Approvals
    </two-text>
    <two-text class="mb-6" type="text-base" tag="p">
      If you have submitted information for manual approval, you can track the
      status and add more information here.
    </two-text>
    <div v-if="isLoading" class="m-8 justify-center flex">
      <LoadingSpinner />
    </div>
    <div v-else-if="isEmptyProductApprovals">
      <EmptyProductApprovals :isConnect="isConnect" />
    </div>
    <div v-else-if="groupDecisions">
      <div
        class="mb-6"
        v-if="
          groupDecisions[DecisionStatusEnum.INFORMATION_REQUESTED]?.length > 0
        "
      >
        <two-text
          class="mb-4 font-heading font-semiblack"
          type="text-xl"
          tag="p"
        >
          More information requested
        </two-text>
        <DecisionItems
          :list-decisions="
            groupDecisions[DecisionStatusEnum.INFORMATION_REQUESTED]
          "
          tag="Information requested"
          color-tag="bg-yellow-100"
          :provide-info="true"
          @handle-provide-info="handleProvideInfo"
          :showCaseRef="true"
          :productCodesAndNames="productCodesAndNames"
        />
      </div>
      <div
        class="mb-6"
        v-if="groupDecisions[DecisionStatusEnum.APPROVED]?.length > 0"
      >
        <two-text
          class="mb-4 font-heading font-semiblack"
          type="text-xl"
          tag="p"
        >
          Approved
        </two-text>
        <DecisionItems
          :list-decisions="groupDecisions[DecisionStatusEnum.APPROVED]"
          tag="Approved"
          color-tag="bg-lime-100"
          :view-history="true"
          @handle-view-history="handleViewCase"
          :show-date="true"
          :productNames="productNames"
          :productCodesAndNames="productCodesAndNames"
        />
      </div>
      <div
        class="mb-6"
        v-if="groupDecisions[DecisionStatusEnum.PENDING]?.length > 0"
      >
        <two-text
          class="mb-4 font-heading font-semiblack"
          type="text-xl"
          tag="p"
        >
          Pending approval
        </two-text>
        <DecisionItems
          :list-decisions="groupDecisions[DecisionStatusEnum.PENDING]"
          tag="Pending approval"
          color-tag="bg-grey-100"
          :view-history="true"
          @handle-view-history="handleViewCase"
          :showCaseRef="true"
          :productCodesAndNames="productCodesAndNames"
        />
      </div>
      <div v-if="groupDecisions[DecisionStatusEnum.DENIED]?.length > 0">
        <two-text
          class="mb-4 font-heading font-semiblack"
          type="text-xl"
          tag="p"
        >
          Declined
        </two-text>
        <DecisionItems
          :list-decisions="groupDecisions[DecisionStatusEnum.DENIED]"
          tag="Declined"
          color-tag="bg-red-100"
          :view-history="true"
          @handle-view-history="handleViewCase"
          :show-date="true"
          :productCodesAndNames="productCodesAndNames"
        />
      </div>
    </div>
    <div v-else-if="errorMessage">
      <Banner :message="errorMessage" :type="BannerType.Alert" />
    </div>
    <div v-else>
      <Banner
        message="Something went wrong. Please, contact Runa support."
        :type="BannerType.Alert"
      />
    </div>
  </div>
</template>
