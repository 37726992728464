<script setup lang="ts">
import { BannerType } from "../utils/types";

const { message, type } = defineProps<{
  message: string;
  type: BannerType;
}>();

let bannerStyles: string = "";
switch (type) {
  case BannerType.Alert:
    bannerStyles = "bg-red-100 border border-red-400 text-red-700 text-center";
    break;
  case BannerType.Info:
    bannerStyles =
      "bg-gray-200 border border-gray-200 text-gray-700 font-light text-sm text-left";
    break;
}

const bannerClass = `border px-4 py-3 mb-5 rounded-lg relative ${bannerStyles}`;
</script>

<template>
  <div :class="bannerClass" role="alert">
    {{ message }}
  </div>
</template>

<style scoped></style>
