type Decision = {
  productCode: string;
};

type DecisionData = {
  [key: string]: Decision[];
};

export default function extractProductNames(
  data: DecisionData | undefined
): string[] {
  let productCodes: string[] = [];

  for (const decisionType in data) {
    const decisions = data[decisionType];
    for (const decision of decisions) {
      productCodes.push(decision.productCode);
    }
  }

  // Remove duplicates
  return [...new Set(productCodes)];
}
