<script setup lang="ts">
import { TwoText, TwoButton } from "@wegift/two-components";

const { isConnect } = defineProps<{
  isConnect: boolean;
}>();

const navigateToApprovalForm = () => {
  const redirectLink = isConnect
    ? `${import.meta.env.VITE_SCION_BASE_URL}/connect/manual-approval-form`
    : `${import.meta.env.VITE_RUNA_PLATFORM_BASE_URL}/forms`;

  // change parent window href as this view is meant to be rendered in an iframe
  window.parent.location.href = redirectLink;
};
</script>

<template>
  <div
    class="rounded-lg border border-grey-100 px-8 py-9 flex items-center justify-between bg-white bg-empty-bg-product-approvals bg-no-repeat bg-right"
  >
    <div class="flex flex-col">
      <TwoText class="font-heading font-semiblack mb-2" type="text-xl">
        There are lots of other products you can apply for
      </TwoText>
      <TwoText type="text-base">
        Before you can start using certain products, some brands require a bit
        more information.
      </TwoText>
    </div>
    <TwoButton class="btn-black" @click="navigateToApprovalForm">
      Request access
    </TwoButton>
  </div>
</template>
